import React, { Component } from 'react'
import SlideToggle from 'react-slide-toggle'
import ReactMarkdown from 'react-markdown'

export default class Question extends Component {
  render() {
    const faqClass = this.props.active ? 'faq faq--active' : 'faq'
    const isCollapsed = !this.props.active
    return (
      <SlideToggle
        collapsed
        collapseEvent={this.props.active == false && Date.now()}
        expandEvent={this.props.active == true && Date.now()}>
        {({ setCollapsibleElement }) => (
          <article className={faqClass}>
            <div
              className='faq__header'
              onClick={() => this.props.toggleQuestion(this.props.id)}>
              <h1 className='faq__question'>
                {this.props.item.frontmatter.title}
              </h1>
            </div>
            <div className='faq__answer' ref={setCollapsibleElement}>
              <ReactMarkdown
                className='faq__answer__inner'
                linkTarget='_blank'
                source={this.props.item.frontmatter.content}></ReactMarkdown>
            </div>
          </article>
        )}
      </SlideToggle>
    )
  }
}
