import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Layout from '@/components/Layout'
import Faq from '@/components/Faq/FAQ'
import ReactHtmlParser from 'react-html-parser'

import SEO from '@/helpers/SEOHelper.js'

const FAQPageTemplate = ({ data, questions }) => (
  <div className='full-height'>
    <section className='flex hero items-center justify-center pt-56 mb-10'>
      <div className='container text-center mx-auto relative'>
        <h1 className='title-1 font-bold  leading-none text-center mx-auto mb-6'>
          {ReactHtmlParser(data.title)}
        </h1>
        <p className='max-w-xl mx-auto mb-10 text-gray'></p>
      </div>
    </section>
    <div className='container-sm pb-20'>
      {data.categories.map((item) => {
        return (
          <section className='faq-group' key={item.name}>
            <h1 className='faq-group__title'>{item.name}</h1>
            <div className='faq-group__question'>
              <Faq content={questions} filtered={item.questions}></Faq>
            </div>
          </section>
        )
      })}
    </div>
  </div>
)

const FAQPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { edges: questions } = data.questions

  return (
    <Layout>
      <Helmet>
        <meta
          name='description'
          content={SEO.description(frontmatter.seo.description)}
        />
        <meta property='og:title' content={SEO.title(frontmatter.seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(frontmatter.seo.description)}
        />
        <meta property='og:image' content={SEO.image('null')} />
        <title>{SEO.title(frontmatter.seo.title)}</title>
      </Helmet>
      <FAQPageTemplate
        data={frontmatter}
        questions={questions}></FAQPageTemplate>
    </Layout>
  )
}

FAQPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default FAQPage

export const pageQuery = graphql`
  query FAQPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "faq" } }) {
      frontmatter {
        title
        content
        categories {
          name
          questions
        }
        seo {
          title
          description
        }
      }
    }
    questions: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "faq" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            id
            content
          }
        }
      }
    }
  }
`
