import React, { Component } from 'react'
import Question from '@/components/Faq/Question'

export default class FAQ extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentActiveQuestion: 0,
    }

    this.handleQuestionChange = this.handleQuestionChange.bind(this)
  }

  handleQuestionChange(id) {
    this.setState((prevState) => {
      return {
        currentActiveQuestion: prevState.currentActiveQuestion === id ? 0 : id,
      }
    })
  }
  render() {
    const questions = this.props.content
    const filtered = this.props.filtered
    return (
      <div className='faq-wrapper'>
        {filtered.length > 0
          ? filtered.map((question_id) => {
              let item = questions.find(
                (question) => question.node.frontmatter.id === question_id,
              )
              if (item) {
                return (
                  <Question
                    key={question_id}
                    id={question_id}
                    item={item.node}
                    active={question_id == this.state.currentActiveQuestion}
                    toggleQuestion={this.handleQuestionChange}
                  />
                )
              } else {
                return ''
              }
            })
          : questions.map(({ node: item }, i) => {
              return (
                <Question
                  key={item.id}
                  id={item.id}
                  item={item}
                  active={item.id == this.state.currentActiveQuestion}
                  toggleQuestion={this.handleQuestionChange}
                />
              )
            })}
      </div>
    )
  }
}
